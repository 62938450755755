import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import { border, color } from '@mui/system';

const PrimaryMenuItem = styled(MenuItem)(() => ({}));

const SecondaryMenuItem = styled(MenuItem)(() => ({
  color: 'grey',
  textDecorationLine: 'underline',
}));

/**
 * Description placeholder
 * @date 10/11/2023 - 5:26:09 PM
 * 
 * @param {{ 
 * options: [ Day, Time, isConsistent, Uid ]; 
 * unOptions: [ Day, Time, isConsistent, Uid ]; 
 * selectedTime: string; 
 * setTime: function; 
 * selectedDay: string; 
 * setDay: function; 
 * setType: function;
 * }} {
  options,
  unOptions,
  selectedTime,
  setTime,
  selectedDay,
  setDay,
  setType,
}
 * @returns {*}
 */
function DayTimeDropDown({
  options,
  unOptions,
  selectedTime,
  setTime,
  selectedDay,
  setDay,
  type,
  setType,
}) {
  const handleChange = (event) => {
    const selection = event.target.value;
    if (selection.length > 0) {
      options = selection.split(' ');
      setDay(options[0]);
      setTime(options[1]);
      setType(options[2]);
    } else {
      setTime('');
      setDay('');
      setType('');
    }
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 150 }}>
        <Select
          labelId="day-time-drop-down-label"
          id="day-time-drop-down-select"
          sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
          value={
            selectedDay && selectedTime
              ? `${selectedDay} ${selectedTime} ${type}`
              : ''
          }
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {unOptions.map((dt) => {
            if (dt[2]) {
              return (
                <MenuItem key={dt[3]} value={`${dt[0]} ${dt[1]} ${dt[4]}`}>
                  {`${dt[0]} ${dt[1]}`}
                </MenuItem>
              );
            }
            return (
              <SecondaryMenuItem
                key={dt[3]}
                value={`${dt[0]} ${dt[1]} ${dt[4]}`}
              >
                {`${dt[0]} (Online)`}
              </SecondaryMenuItem>
            );
          })}
          {options.map((dt) => {
            if (dt[2]) {
              return (
                <MenuItem key={dt[3]} value={`${dt[0]} ${dt[1]} ${dt[4]}`}>
                  {`${dt[0]} ${dt[1]}`}
                </MenuItem>
              );
            }
            return (
              <SecondaryMenuItem
                key={dt[3]}
                value={`${dt[0]} ${dt[1]} ${dt[4]}`}
              >
                {`${dt[0]} (Online)`}
              </SecondaryMenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default DayTimeDropDown;
