import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { useSelector } from 'react-redux';
import Loginpage from './pages/Loginpage';
import Faculty from './pages/StudentList/Faculty';
import Admin from './pages/StudentList/Admin';
import CoursePlanner from './pages/CoursePlanner';
import StudentInfo from './pages/StudentInfoView/StudentInfo';
import AdminImportStudents from './pages/Admin/components/AdminImportStudents';
import AdminImportGrades from './pages/Admin/AutoGradeImportModel/index';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard/index';
import DownloadPage from './pages/DownloadPage';
import ThemeCustomization from './themes';
import StudentReport from './pages/StudentReport/index';
import { SelectionsContextProvider } from './context/SelectionsContext';
import GraduationReport from './pages/StudentReport/GraduationReport';
import AbsentReport from './pages/StudentReport/AbsentReport';
import DiscontinuedReport from './pages/StudentReport/DiscontinuedReport';
import GraduatedReport from './pages/StudentReport/GraduatedReport';
import CohortView from './pages/StudentReport/CohortView';
import DeferralReport from './pages/StudentReport/DeferralReport';
import WithdrawReport from './pages/StudentReport/WithdrawReport';
import { CertificationContextProvider } from './context/CertificationContext';
import ReqReport from './pages/StudentReport/ReqReport';
import MainLayout from './layout/MainLayout';
import ScrollTop from './components/ScrollTop';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import TemplateValidationReport from './pages/StudentReport/TemplateValidationReport';
import ProbationReport from './pages/StudentReport/ProbationReport';
import GradeReport from './pages/StudentReport/GradeReport';
import AdvisorDashboard from './pages/Dashboard/index_advisor';

function App() {
  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );

  const role = authenticationStatus.authenticationRole;

  let redirectPathAfterLogin = '/';
  switch (authenticationStatus.authenticationRole) {
    case 'Staff':
      redirectPathAfterLogin = '/admin';
      break;
    case 'Faculty':
      redirectPathAfterLogin = '/faculty';
      break;
    default:
      break;
  }

  return (
    <Router basename={process.env.PUBLIC_URL || ''}>
      <ScrollTop>
        <ThemeCustomization>
          <Route>
            {(authenticationStatus.isExpired || role == false) && (
              <Redirect to="/" />
            )}
            {role !== false ? (
              <SelectionsContextProvider>
                <CertificationContextProvider>
                  <MainLayout>
                    <Switch>
                      <PrivateRoute path="/" exact>
                        <Redirect to={redirectPathAfterLogin} />
                      </PrivateRoute>
                      <PrivateRoute path="/CoursePlan">
                        <CoursePlanner />
                      </PrivateRoute>
                      <PrivateRoute path="/studentinfo">
                        <StudentInfo />
                      </PrivateRoute>
                      <PrivateRoute path="/dashboard">
                        <Dashboard />
                      </PrivateRoute>
                      <PrivateRoute path="/advisor-dashboard">
                        <AdvisorDashboard />
                      </PrivateRoute>
                      <PrivateRoute path="/student-report/download-files">
                        <DownloadPage />
                      </PrivateRoute>
                      <PrivateRoute path="/student-report/template-validation">
                        <TemplateValidationReport />
                      </PrivateRoute>
                      <PrivateRoute path="/student-report">
                        <StudentReport />
                      </PrivateRoute>
                      <PrivateRoute path="/requirement-report">
                        <ReqReport />
                      </PrivateRoute>
                      <PrivateRoute path="/probation-report">
                        <ProbationReport />
                      </PrivateRoute>
                      <PrivateRoute path="/graduated-report">
                        <GraduatedReport />
                      </PrivateRoute>
                      <PrivateRoute path="/graduation-report">
                        <GraduationReport />
                      </PrivateRoute>
                      <PrivateRoute path="/deferral-report">
                        <DeferralReport />
                      </PrivateRoute>
                      <PrivateRoute path="/withdraw-report">
                        <WithdrawReport />
                      </PrivateRoute>
                      <PrivateRoute path="/absent-report">
                        <AbsentReport />
                      </PrivateRoute>
                      <PrivateRoute path="/discontinued-report">
                        <DiscontinuedReport />
                      </PrivateRoute>
                      <PrivateRoute path="/cohort-view">
                        <CohortView />
                      </PrivateRoute>
                      <PrivateRoute path="/grade-report">
                        <GradeReport />
                      </PrivateRoute>

                      {/* Faculty Pages */}
                      <PrivateRoute path="/faculty">
                        <Faculty />
                      </PrivateRoute>

                      {/*  Staff Pages  */}
                      <PrivateRoute path="/admin">
                        <Admin />
                      </PrivateRoute>
                      <PrivateRoute path="/AdminImportStudents">
                        <AdminImportStudents />
                      </PrivateRoute>
                      <PrivateRoute path="/AdminImportGrades">
                        <AdminImportGrades />
                      </PrivateRoute>
                    </Switch>
                  </MainLayout>
                </CertificationContextProvider>
              </SelectionsContextProvider>
            ) : (
              <>
                <Route path="/" exact>
                  <Loginpage />
                </Route>
                {/*  Reset Password  */}
                <Route path="/reset-password">
                  <ResetPassword />
                </Route>
              </>
            )}
          </Route>
          {/*<ScrollTop>*/}

          {/*</ScrollTop>*/}
        </ThemeCustomization>
      </ScrollTop>
    </Router>
  );
}

export default App;
