/* eslint-disable no-restricted-syntax */
import _, { isNull } from 'underscore';
import CourseServices from '../../../api/services/courseServices';

// import useSWR from 'swr';
// const { data, error, isLoading } = useSWR(
//   studentType,
//   CourseServices.getCoursesForStudent
// );

/**
 *
 * @param {string} studentType
 * @return {Promise<(*|boolean|[])[]>}
 */
async function CourseFetch(studentType) {
  let data = [];
  let error = false;
  let loading;

  try {
    const response = await CourseServices.getCoursesForStudent(
      studentType == 'ONCMP' ? {} : { type: 'ONLINE' }
    );
    const finalCoursesArr = _.groupBy(
      response.allCourses,
      (course) => course.course_id
    );

    const formattedCoursesArr = [];
    for (const coursesKey in finalCoursesArr) {
      const course = finalCoursesArr[coursesKey];
      const unavailTerm = [];
      const availTerm = [];
      const availYear = [];
      const courseData = [];
      const courseOptions = {
        Spring: [],
        Summer: [],
        Fall: [],
      };
      const unofferedCourseOptions = {
        Spring: [],
        Summer: [],
        Fall: [],
      };
      let isOffered = false;

      // @TODO: Check aggregate logic
      for (const courseObj of course) {
        // Edge case
        if (courseObj.pre_req == 'MOCK') {
          continue;
        }

        // Normal Case
        courseData.push(courseObj);
        if (courseObj.is_offered != false) isOffered = true;
        const optionObj = [
          courseObj.day,
          courseObj.time,
          courseObj.type == studentType,
          courseObj.uid,
          courseObj.type,
        ];
        switch (isOffered) {
          case true: {
            if (!_.contains(availTerm, courseObj.term))
              availTerm.push(courseObj.term);
            if (!_.contains(availYear, courseObj.year))
              availYear.push(courseObj.year);
            courseOptions[courseObj.term].push(optionObj);
            break;
          }
          case false: {
            if (!_.contains(availTerm, courseObj.term))
              unavailTerm.push(courseObj.term);
            if (!_.contains(optionObj, courseOptions))
              unofferedCourseOptions[courseObj.term].push(optionObj);
            break;
          }
          default:
        }
      }
      formattedCoursesArr.push({
        courseId: course[0].course_id,
        courseUid: '',
        courseTitle: course[0].course_name,
        courseDay: '',
        courseTime: '',
        courseTerm: '',
        courseYear: '',
        courseMark: {
          conflict: false,
        },
        courseAvailTerm: availTerm,
        courseUnavailTerm: unavailTerm,
        courseAvailYear: availYear,
        isOffered,
        courseOptions,
        unofferedCourseOptions,
        courseData,
      });
    }
    data = [...formattedCoursesArr];
    loading = false;
  } catch (e) {
    error = e;
    loading = false;
  }

  return [loading, error, data];
}

export default CourseFetch;
